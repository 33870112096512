@use "./values" as values;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&family=Titillium+Web:wght@600&display=swap');

a {
    text-decoration: none;
    font-weight: bold;
    // color: values.$main;
}

html {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 16px;
}

.fill-screen {
    min-width: 100vw;
    min-height: 100vh;
}

.center {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.column {
    flex-direction: column;
}
