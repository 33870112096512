
@use "sass:math";
@use "./values" as values;

$font-size-1: 48px;
$font-size-2: $font-size-1 * 0.618;
$font-size-3: $font-size-2 * 0.618;
$font-size-4: $font-size-3 * 0.618;

#hero-section {
    padding-top: 6em;
    padding-bottom: 3em;
    color: values.$white1;
    background-color: values.$main;
}

#hero-title {
    font-size: $font-size-1;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
    margin-top: 0px;
    margin-bottom: 0.3em;
}

#hero-jobtitle, #hero-company {
    margin-bottom: 0.2em;
    font-family: 'Raleway', sans-serif;
    // font-size: $font-size-1 * math.pow(0.618, 2);
}

#hero-jobtitle {
    letter-spacing: 0.1em;
    font-weight: 300;
}

#hero-company {
    font-style: italic;
    font-weight: 300;
}
