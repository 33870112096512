
@use "../values" as values;

.modal-wrapper {
    position: absolute;
    background-color: transparent;
}

@keyframes fadeInAnim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.modal-background {
    width: 100vw;
    min-height: 100vh;
    height: 100%;

    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;

    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background: #323232a6;

    z-index: 10;
}

.modal {
    position: relative;
    animation: fadeInAnim ease 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 20px 0px 0px;

    background-color: values.$white1;
    -webkit-box-shadow: 0px 0px 20px -6px #000000; 
    box-shadow: 0px 0px 30px -6px #000000;

    max-width: 900px;
    padding: 26px;
    padding-top: 0px;
    margin-top: 100px;
    margin-bottom: 100px;

    .close-modal-button {
        position: absolute;
        color: values.$white1;
        background-color: values.$black2;
        font-weight: bold;
        right: 0;
        top: 0;
        padding: 10px 20px 10px 20px;
        border-radius: 0px 15px 0px 10px;

        &:hover {
            cursor: pointer;
            background-color: red;
        }
    }

    .project-image {
        max-width: 100%;
    }

    #modal-title {
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: values.$main;
        color: values.$black1;
        padding: 22px 16px 16px 16px;
        width: 80%;
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        margin-bottom: 25px;
        border-bottom: 1px dotted values.$black1;
    }

    .tech-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 22px;
        
        & > * {
            background-color: values.$white2;
            border-radius: 1000px;
            padding: 1px 10px 1px 10px;
            margin: 0px 1px 6px 1px;
            font-size: 16px;
        }
    }

    .project-description{
        margin-top: 16px;
        margin-bottom: 26px;
    }

    .project-links {
        margin-bottom: 26px;

        & > a {
            padding: 10px;
            padding-right: 0px;

            &:not(:last-child):after {
                content:'\2022';
                padding-left: 10px;
            }
        }
    }

    .highlights {
        margin-top: 0px;
        width: 100%;

        & > li {
            margin-bottom: 12px;
        }

        a {
            font-weight: 300;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}