@use "../values" as values;

#projects-section {
    display: flex;
    flex-direction: column;

    .tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        min-width: 100vw;
        font-weight: bold;
        color: values.$white1;
        background-color: values.$main;

        & > * {
            padding: 10px 16px 8px 16px;
            margin: 0px 3px 0px 3px;
            white-space: nowrap;
            border-radius: 2px 2px 0px 0px;
        }
    }

    .tab {
        &.selected, &:hover {
            // box-shadow: values.$shadow;
            background-color: values.$white1;
            color: black;
            cursor: default;
        }
    }

    .p-list-description-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-list-description {
        text-align: center;
        padding: 30px;
        padding-top: 60px;
        color: values.$black2;
    }

    .project-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    $project-card-height: 130px;

    .project-item-card {

        position: relative;
        display: flex;
        flex-direction: row;
        max-width: 600px;
        width: 80vw;
        overflow: hidden;
        margin-bottom: 15px;
        justify-content: flex-start;
        align-items: flex-start;
        height: $project-card-height;border: 1px solid values.$white2;
        border-right: none;
        transition: all 0.1s ease-out;

        .rating {
            position: absolute;
            background-color: values.$main;
            color: values.$white1;
            padding: 2px 10px 2px 10px;
        }

        .project-card-image {
            object-fit: cover;
            height: 100%;
            width: 180px;
            flex-grow: 1;
        }

        .project-card-info {
            padding: 20px 0px 20px 25px;
            flex-grow: 1;
            min-width: 0px;
        }

        .project-description {
            display: -webkit-box;
            line-height: 24px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            color: values.$black2;
        }

        .foreground-gradient {
            width: 100%;
            max-width: 600px;
            height: $project-card-height;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 73%, rgba(255,255,255,1) 100%);
        }
        
        &:hover {
            cursor: pointer;
            margin-left: -30px;
        }

        .project-top-bar {
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            flex-direction: row;
            margin-bottom: 9px;
            align-items: center;

            & > span {
                white-space: nowrap;

                &:not(:last-child) {
                    padding-right: 2px;
                }

                &.tech-pill {
                    background-color: values.$white2;
                    color: values.$black2;
                    border-radius: 1000px;
                    padding: 1px 10px 1px 10px;
                    margin: 0px 1px 0px 1px;
                    font-size: 16px;
                }

                &.project-name {
                    font-size: 16px;
                    font-weight: bold;
                    width: fit-content;
                    padding: 5px;
                    padding-right: 20px;
                    padding-left: 0px;
                }
            }            
        }
    }
}


