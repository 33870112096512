@use "../values" as values;

#tech-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 22px;
    justify-content: flex-start;
    user-select: none;
    max-width: 700px;
    margin-bottom: 15px;

    & > * {
        margin: 4px;
        font-size: 13px;
        padding: 2px 10px 2px 10px;
    }
}

.tech {
    background-color: values.$white2;
    color: values.$black1;
    border-radius: 100px;

    &:hover {
        cursor: pointer;
        background: values.$main;
        color: values.$white1;
    }

    &.selected {
        background: values.$black2;
        color: values.$white1;
    }
}