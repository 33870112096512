
@use "../values.scss" as values;

.slideshow {

    max-width: 80vw;
    // width: 100%;

    & > img, & > iframe {
        max-width: 100%;
        width: 100%;
        box-shadow: black 0px 0px 39px -12px;
    }

    .thumbnails {

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        max-width: 100%;

        .thumbnail {

            width: 100px;
            height: 60px;
            padding: 10px;

            object-fit:contain;

            &:hover, &.selected {
                // opacity: 0.7;
                background-color: rgb(204, 204, 204);
                // background-color: values.$main;
                cursor: pointer;
            }
        }
    }
}